// TODO think about creating an interface for 'IntlKeys' as an additional failsafe
export const IntlKeys = {
  common: {
    showMore: "common.showMore",
    loading: "common.loading",
    pageLoading: "common.pageLoading",
    new: "common.new",
    name: "common.name",
    email: "common.email",
    value: "common.value",
    back: "common.back",
    send: "common.send",
    address: "common.address",
    noResults: "common.noResults",
    searching: "common.searching",
    close: "common.close",
    cancel: "common.cancel",
    discard: "common.discard",
    open: "common.open",
    apply: "common.apply",
    import: "common.import",
    actions: "common.actions",
    dataImported: "common.dataImported",
    errorOccurred: "common.errorOccurred",
    status: "common.status",
    price: "common.price",
    objectSuccessfullyDeleted: "common.objectSuccessfullyDeleted",
    objectDeleteConfirmation: "common.objectDeleteConfirmation",
    objectDeleteTitle: "common.objectDeleteTitle",
    page: "common.page",
    delete: "common.delete",
    deleteAll: "common.deleteAll",
    copy: "common.copy",
    unlock: "common.unlock",
    export: "common.export",
    unknown: "common.unknown",
    goToPage: "common.goToPage",
    show: "common.show",
    create: "common.create",
    save: "common.save",
    searchFor: "common.searchFor",
    from: "common.from",
    statuses: {
      ACTIVE: "common.statuses.ACTIVE",
      INACTIVE: "common.statuses.INACTIVE",
    },
    yes: "common.yes",
    no: "common.no",
    marketingType: "common.marketingType",
    transportationTypes: {
      walking: "common.transportationTypes.walking",
      driving: "common.transportationTypes.driving",
      cycling: "common.transportationTypes.cycling",
    },
    myLocation: "common.myLocation",
    userDefined: "common.userDefined",
    custom: "common.custom",
    successfullyCopiedToClipboard: "common.successfullyCopiedToClipboard",
    qrCode: "common.qrCode",
    pdf: "common.pdf",
    png: "common.png",
    transportationUnits: {
      KILOMETERS: "common.transportationUnits.KILOMETERS",
      MINUTES: "common.transportationUnits.MINUTES",
    },
    description: "common.description",
    addressExpired: "common.addressExpired",
    help: "common.help",
    languages: {
      en: "common.languages.en",
      de: "common.languages.de",
      fr: "common.languages.fr",
      nl: "common.languages.nl",
      it: "common.languages.it",
      es: "common.languages.es",
    },
  },

  imageUpload: {
    supportedFormats: "imageUpload.supportedFormats",
    defaultLabel: "imageUpload.defaultLabel",
    defaultUploadLabel: "imageUpload.defaultUploadLabel",
    sizeError: "imageUpload.sizeError",
  },

  googleAutocomplete: {
    enterAddress: "googleAutocomplete.enterAddress",
  },

  nav: {
    environmentalAnalysis: "nav.environmentalAnalysis",
    aiAssistant: "nav.aiAssistant",
    realEstates: "nav.realEstates",
    potentialCustomers: "nav.potentialCustomers",
    cards: "nav.cards",
    products: "nav.products",
    profile: "nav.profile",
    companyProfile: "nav.companyProfile",
    logout: "nav.logout",
    menu: "nav.menu",
  },

  environmentalAnalysis: {
    createBtn: "environmentalAnalysis.createBtn",
    updateBtn: "environmentalAnalysis.updateBtn",
    error: "environmentalAnalysis.error",
    queryLimitReached: "environmentalAnalysis.queryLimitReached",
    modalBtn: "environmentalAnalysis.modalBtn",
    addressOfTheRealState: "environmentalAnalysis.addressOfTheRealState",
    currentLocation: "environmentalAnalysis.currentLocation",
    lastEntries: "environmentalAnalysis.lastEntries",
    analysisRadius: "environmentalAnalysis.analysisRadius",
    availableIn: "environmentalAnalysis.availableIn",
    unit: "environmentalAnalysis.unit",
    targetGroups: "environmentalAnalysis.targetGroups",
    importantAddresses: "environmentalAnalysis.importantAddresses",
    addAddress: "environmentalAnalysis.addAddress",
    designation: "environmentalAnalysis.designation",
    airport: "environmentalAnalysis.airport",
    station: "environmentalAnalysis.station",
    shuttleRoute: "environmentalAnalysis.shuttleRoute",
    highlightOfRegion: "environmentalAnalysis.highlightOfRegion",
    ownDesignation: "environmentalAnalysis.ownDesignation",
  },

  snapshotEditor: {
    map: "snapshotEditor.map",
    needsFilter: "snapshotEditor.needsFilter",
    editor: "snapshotEditor.editor",
    data: "snapshotEditor.data",
    current: "snapshotEditor.current",
    cardInYourBranding: "snapshotEditor.cardInYourBranding",
    talkToUs: "snapshotEditor.talkToUs",
    hideIndividualPOIs: "snapshotEditor.hideIndividualPOIs",
    POIDetailFilter: "snapshotEditor.POIDetailFilter",
    none: "snapshotEditor.none",
    distance: "snapshotEditor.distance",
    showOnly: "snapshotEditor.showOnly",
    meterRadius: "snapshotEditor.meterRadius",
    quantity: "snapshotEditor.quantity",
    showOnlyTheText: "snapshotEditor.showOnlyTheText",
    POIs: "snapshotEditor.POIs",
    enterValidValue: "snapshotEditor.enterValidValue",
    configuration: "snapshotEditor.configuration",
    configurationDesc: "snapshotEditor.configurationDesc",
    templates: "snapshotEditor.templates",
    cardStyle: "snapshotEditor.cardStyle",
    menuStyle: "snapshotEditor.menuStyle",
    standard: "snapshotEditor.standard",
    minimal: "snapshotEditor.minimal",
    preselectedProfile: "snapshotEditor.preselectedProfile",
    showIcon: "snapshotEditor.showIcon",
    showAddress: "snapshotEditor.showAddress",
    showStreetViewLink: "snapshotEditor.showStreetViewLink",
    groupPOIOnZoom: "snapshotEditor.groupPOIOnZoom",
    hideIsochrones: "snapshotEditor.hideIsochrones",
    objectInfoDisplayed: "snapshotEditor.objectInfoDisplayed",
    minimizeOnIframe: "snapshotEditor.minimizeOnIframe",
    referenceCard: "snapshotEditor.referenceCard",
    needsFilterForRealEstate: "snapshotEditor.needsFilterForRealEstate",
    resetColor: "snapshotEditor.resetColor",
    resetIcon: "snapshotEditor.resetIcon",
    objectTooltip: "snapshotEditor.objectTooltip",
    hideCost: "snapshotEditor.hideCost",
    showObjectType: "snapshotEditor.showObjectType",
    hideOtherPropertyFeatures: "snapshotEditor.hideOtherPropertyFeatures",
    primaryColor: "snapshotEditor.primaryColor",
    cardsIcon: "snapshotEditor.cardsIcon",
    uploadIcon: "snapshotEditor.uploadIcon",
    addressNotPublished: "snapshotEditor.addressNotPublished",
    selectRatio: "snapshotEditor.selectRatio",
    selectRatioTooltip: "snapshotEditor.selectRatioTooltip",
    userDefined: "snapshotEditor.userDefined",
    showQRCode: "snapshotEditor.showQRCode",
    showLegend: "snapshotEditor.showLegend",
    showLegendTooltip: "snapshotEditor.showLegendTooltip",
    showIsochrones: "snapshotEditor.showIsochrones",
    crop: "snapshotEditor.crop",
    cropSuccess: "snapshotEditor.cropSuccess",
    portrait: "snapshotEditor.portrait",
    helpAndFeedback: "snapshotEditor.helpAndFeedback",
    startTour: "snapshotEditor.startTour",
    feedbackQuestion: "snapshotEditor.feedbackQuestion",
    supportQuestion: "snapshotEditor.supportQuestion",
    specifyTypeOfFeedback: "snapshotEditor.specifyTypeOfFeedback",
    enterDescription: "snapshotEditor.enterDescription",
    typeOfFeedback: "snapshotEditor.typeOfFeedback",
    improvement: "snapshotEditor.improvement",
    error: "snapshotEditor.error",
    other: "snapshotEditor.other",
    pleaseSelect: "snapshotEditor.pleaseSelect",
    moreInfo: "snapshotEditor.moreInfo",
    objectType: "snapshotEditor.objectType",
    size: "snapshotEditor.size",
    directlyToTheObject: "snapshotEditor.directlyToTheObject",
    hide: "snapshotEditor.hide",
    furtherObjects: "snapshotEditor.furtherObjects",
    dataTab: {
      mapSection: "snapshotEditor.dataTab.mapSection",
      mapSectionDescription: "snapshotEditor.dataTab.mapSectionDescription",
      useScreenshotBtn: "snapshotEditor.dataTab.useScreenshotBtn",
      deleteAllMapSectionsConfirmation:
        "snapshotEditor.dataTab.deleteAllMapSectionsConfirmation",
      deleteThisMapSectionsConfirmation:
        "snapshotEditor.dataTab.deleteThisMapSectionsConfirmation",
      myLocation: "snapshotEditor.dataTab.myLocation",
      unlockInteractiveMap: "snapshotEditor.dataTab.unlockInteractiveMap",
      digitalMedia: "snapshotEditor.dataTab.digitalMedia",
      digitalMediaDescription: "snapshotEditor.dataTab.digitalMediaDescription",
      publicLink: "snapshotEditor.dataTab.publicLink",
      qrCodeLink: "snapshotEditor.dataTab.qrCodeLink",
      download: "snapshotEditor.dataTab.download",
      saveAsPreset: "snapshotEditor.dataTab.saveAsPreset",
      saveAsPresetSuccess: "snapshotEditor.dataTab.saveAsPresetSuccess",
      saveAsPresetError: "snapshotEditor.dataTab.saveAsPresetError",
      qrCodeFileName: "snapshotEditor.dataTab.qrCodeFileName",
      legendPOIIcons: "snapshotEditor.dataTab.legendPOIIcons",
      iframeForWebsite: "snapshotEditor.dataTab.iframeForWebsite",
      unlockLocationExpose: "snapshotEditor.dataTab.unlockLocationExpose",
      reportsAndLocationExpose:
        "snapshotEditor.dataTab.reportsAndLocationExpose",
      reportsAndLocationExposeDescription:
        "snapshotEditor.dataTab.reportsAndLocationExposeDescription",
      generateLocationExpose: "snapshotEditor.dataTab.generateLocationExpose",
      environmentalAnalysisPDF:
        "snapshotEditor.dataTab.environmentalAnalysisPDF",
      environmentalAnalysisDOC:
        "snapshotEditor.dataTab.environmentalAnalysisDOC",
      environmentalAnalysis: "snapshotEditor.dataTab.environmentalAnalysis",
      overviewPDF: "snapshotEditor.dataTab.overviewPDF",
      environmentalAnalysisExport:
        "snapshotEditor.dataTab.environmentalAnalysisExport",
      cheatSheetExport: "snapshotEditor.dataTab.cheatSheetExport",
      pictures: "snapshotEditor.dataTab.pictures",
      insights: "snapshotEditor.dataTab.insights",
      censusAtlas: "snapshotEditor.dataTab.censusAtlas",
      federalElections: "snapshotEditor.dataTab.federalElections",
      particularMatterPollution:
        "snapshotEditor.dataTab.particularMatterPollution",
      tooManyGroups: "snapshotEditor.dataTab.tooManyGroups",
      selectedPictures: "snapshotEditor.dataTab.selectedPictures",
      noMapSelectionsSaved: "snapshotEditor.dataTab.noMapSelectionsSaved",
      selectedLocalities: "snapshotEditor.dataTab.selectedLocalities",
      cardLegend: "snapshotEditor.dataTab.cardLegend",
      neighborhoodDemographic: "snapshotEditor.dataTab.neighborhoodDemographic",
      surroundings: "snapshotEditor.dataTab.surroundings",
      pleaseCompleteAllSteps: "snapshotEditor.dataTab.pleaseCompleteAllSteps",
      generateAIText: "snapshotEditor.dataTab.generateAIText",
      locationDescription: "snapshotEditor.dataTab.locationDescription",
      targetGroup: "snapshotEditor.dataTab.targetGroup",
      targetGroupName: "snapshotEditor.dataTab.targetGroupName",
      textTonality: "snapshotEditor.dataTab.textTonality",
      desiredTextLength: "snapshotEditor.dataTab.desiredTextLength",
      customTextTooltip: "snapshotEditor.dataTab.customTextTooltip",
      furtherAIInstructions: "snapshotEditor.dataTab.furtherAIInstructions",
      resultsText: "snapshotEditor.dataTab.resultsText",
      userDefinedText: "snapshotEditor.dataTab.userDefinedText",
      aiFieldOfKnowledge: "snapshotEditor.dataTab.aiFieldOfKnowledge",
      POITable: "snapshotEditor.dataTab.POITable",
      mediaAndFormat: "snapshotEditor.dataTab.mediaAndFormat",
      enterYourOwnTargetGroup: "snapshotEditor.dataTab.enterYourOwnTargetGroup",
      defaultTargetGroupName: "snapshotEditor.dataTab.defaultTargetGroupName",
      transparentBackground: "snapshotEditor.dataTab.transparentBackground",
      selectedMapSections: "snapshotEditor.dataTab.selectedMapSections",
      costs: "snapshotEditor.dataTab.costs",
      overview: "snapshotEditor.dataTab.overview",
      sendTo: "snapshotEditor.dataTab.sendTo",
      surroundingsHeader: "snapshotEditor.dataTab.surroundingsHeader",
      distance: "snapshotEditor.dataTab.distance",
      scanAndNew: "snapshotEditor.dataTab.scanAndNew",
      discoverResidentialArea: "snapshotEditor.dataTab.discoverResidentialArea",
      mobilityCriteria: "snapshotEditor.dataTab.mobilityCriteria",
      yourNewProperty: "snapshotEditor.dataTab.yourNewProperty",
      nextPlace: "snapshotEditor.dataTab.nextPlace",
      descriptionPerKm: "snapshotEditor.dataTab.descriptionPerKm",
      germany: "snapshotEditor.dataTab.germany",
      resultSecondVote: "snapshotEditor.dataTab.resultSecondVote",
      resultLastElection: "snapshotEditor.dataTab.resultLastElection",
      particlePollutionDescription:
        "snapshotEditor.dataTab.particlePollutionDescription",
      summary: "snapshotEditor.dataTab.summary",
      locationOverview: "snapshotEditor.dataTab.locationOverview",
      noLocationSelected: "snapshotEditor.dataTab.noLocationSelected",
      automaticTextsTitle: "snapshotEditor.dataTab.automaticTextsTitle",
      automaticTextsDescription:
        "snapshotEditor.dataTab.automaticTextsDescription",
      unlockAiTexts: "snapshotEditor.dataTab.unlockAiTexts",
      aiTextsFromMagicPen: "snapshotEditor.dataTab.aiTextsFromMagicPen",
      aiDescription: "snapshotEditor.dataTab.aiDescription",
      pleaseEnterRequirements: "snapshotEditor.dataTab.pleaseEnterRequirements",
      discardText: "snapshotEditor.dataTab.discardText",
      applyChange: "snapshotEditor.dataTab.applyChange",
      discardChange: "snapshotEditor.dataTab.discardChange",
      improveDescription: "snapshotEditor.dataTab.improveDescription",
      improvePlaceholder: "snapshotEditor.dataTab.improvePlaceholder",
      enterNewWishes: "snapshotEditor.dataTab.enterNewWishes",
      improveLastText: "snapshotEditor.dataTab.improveLastText",
      generate: "snapshotEditor.dataTab.generate",
      errorOccurred: "snapshotEditor.dataTab.errorOccurred",
      contentDetails: "snapshotEditor.dataTab.contentDetails",
      targetFormat: "snapshotEditor.dataTab.targetFormat",
      generateQuestion: "snapshotEditor.dataTab.generateQuestion",
      realEstate: "snapshotEditor.dataTab.realEstate",
      realEstatePlaceholder: "snapshotEditor.dataTab.realEstatePlaceholder",
      selectRealEstate: "snapshotEditor.dataTab.selectRealEstate",
      objectType: "snapshotEditor.dataTab.objectType",
      objectProperties: "snapshotEditor.dataTab.objectProperties",
      noObjectProperties: "snapshotEditor.dataTab.noObjectProperties",
      plotAreaInMeters: "snapshotEditor.dataTab.plotAreaInMeters",
      sizeInMeters: "snapshotEditor.dataTab.sizeInMeters",
      fromPlotAreaInMeters: "snapshotEditor.dataTab.fromPlotAreaInMeters",
      fromSizeInMeters: "snapshotEditor.dataTab.fromSizeInMeters",
      energyEfficiencyClass: "snapshotEditor.dataTab.energyEfficiencyClass",
      copyTheText: "snapshotEditor.dataTab.copyTheText",
      yourRequestToAI: "snapshotEditor.dataTab.yourRequestToAI",
      textualInformation: "snapshotEditor.dataTab.textualInformation",
      ownFiles: "snapshotEditor.dataTab.ownFiles",
      ownFilesDescription: "snapshotEditor.dataTab.ownFilesDescription",
      loadingClickAgain: "snapshotEditor.dataTab.loadingClickAgain",
      saveTargetGroup: "snapshotEditor.dataTab.saveTargetGroup",
      interactiveMap: "snapshotEditor.dataTab.interactiveMap",
      openAITypesSideBarLabel: {
        LOCATION_DESCRIPTION:
          "snapshotEditor.dataTab.openAITypesSideBarLabel.LOCATION_DESCRIPTION",
        REAL_ESTATE_DESCRIPTION:
          "snapshotEditor.dataTab.openAITypesSideBarLabel.REAL_ESTATE_DESCRIPTION",
        EQUIPMENT_DESCRIPTION:
          "snapshotEditor.dataTab.openAITypesSideBarLabel.EQUIPMENT_DESCRIPTION",
        LOCATION_REAL_ESTATE_DESCRIPTION:
          "snapshotEditor.dataTab.openAITypesSideBarLabel.LOCATION_REAL_ESTATE_DESCRIPTION",
        FORMAL_TO_INFORMAL:
          "snapshotEditor.dataTab.openAITypesSideBarLabel.FORMAL_TO_INFORMAL",
        GENERAL_QUESTION:
          "snapshotEditor.dataTab.openAITypesSideBarLabel.GENERAL_QUESTION",
        FACEBOOK_POST:
          "snapshotEditor.dataTab.openAITypesSideBarLabel.FACEBOOK_POST",
        INSTAGRAM_CAPTION:
          "snapshotEditor.dataTab.openAITypesSideBarLabel.INSTAGRAM_CAPTION",
        MICRO_LOC_DESC:
          "snapshotEditor.dataTab.openAITypesSideBarLabel.MICRO_LOC_DESC",
        MACRO_LOC_DESC:
          "snapshotEditor.dataTab.openAITypesSideBarLabel.MACRO_LOC_DESC",
        DISTRICT_DESC:
          "snapshotEditor.dataTab.openAITypesSideBarLabel.DISTRICT_DESC",
      },
      openAITypesOptionLabel: {
        LOCATION_DESCRIPTION:
          "snapshotEditor.dataTab.openAITypesOptionLabel.LOCATION_DESCRIPTION",
        REAL_ESTATE_DESCRIPTION:
          "snapshotEditor.dataTab.openAITypesOptionLabel.REAL_ESTATE_DESCRIPTION",
        EQUIPMENT_DESCRIPTION:
          "snapshotEditor.dataTab.openAITypesOptionLabel.EQUIPMENT_DESCRIPTION",
        LOCATION_REAL_ESTATE_DESCRIPTION:
          "snapshotEditor.dataTab.openAITypesOptionLabel.LOCATION_REAL_ESTATE_DESCRIPTION",
        FORMAL_TO_INFORMAL:
          "snapshotEditor.dataTab.openAITypesOptionLabel.FORMAL_TO_INFORMAL",
        GENERAL_QUESTION:
          "snapshotEditor.dataTab.openAITypesOptionLabel.GENERAL_QUESTION",
        FACEBOOK_POST:
          "snapshotEditor.dataTab.openAITypesOptionLabel.FACEBOOK_POST",
        INSTAGRAM_CAPTION:
          "snapshotEditor.dataTab.openAITypesOptionLabel.INSTAGRAM_CAPTION",
        MICRO_LOC_DESC:
          "snapshotEditor.dataTab.openAITypesOptionLabel.MICRO_LOC_DESC",
        MACRO_LOC_DESC:
          "snapshotEditor.dataTab.openAITypesOptionLabel.MACRO_LOC_DESC",
        DISTRICT_DESC:
          "snapshotEditor.dataTab.openAITypesOptionLabel.DISTRICT_DESC",
      },
      publicLinks: "snapshotEditor.dataTab.publicLinks",
      useChrome: "snapshotEditor.dataTab.useChrome",
      textLength: {
        SHORT: "snapshotEditor.dataTab.textLength.SHORT",
        MEDIUM: "snapshotEditor.dataTab.textLength.MEDIUM",
        LONG: "snapshotEditor.dataTab.textLength.LONG",
        SPECIFIC: "snapshotEditor.dataTab.textLength.SPECIFIC",
      },
      maxCharactersLength: "snapshotEditor.dataTab.maxCharactersLength",
      tonalities: {
        FORMAL_SERIOUS: "snapshotEditor.dataTab.tonalities.FORMAL_SERIOUS",
        EASYGOING_YOUTHFUL:
          "snapshotEditor.dataTab.tonalities.EASYGOING_YOUTHFUL",
      },
      customTexts: {
        POPULATION: "snapshotEditor.dataTab.customTexts.POPULATION",
        SEO: "snapshotEditor.dataTab.customTexts.SEO",
        REGION: "snapshotEditor.dataTab.customTexts.REGION",
        POIS: "snapshotEditor.dataTab.customTexts.POIS",
        OFFICE: "snapshotEditor.dataTab.customTexts.OFFICE",
        COMMERCIAL: "snapshotEditor.dataTab.customTexts.COMMERCIAL",
        RESIDENTIAL: "snapshotEditor.dataTab.customTexts.RESIDENTIAL",
        TEASER: "snapshotEditor.dataTab.customTexts.TEASER",
        CUSTOM: "snapshotEditor.dataTab.customTexts.CUSTOM",
        NONE: "snapshotEditor.dataTab.customTexts.NONE",
      },
      realEstateTypes: {
        ROOM: "snapshotEditor.dataTab.realEstateTypes.ROOM",
        HOUSE: "snapshotEditor.dataTab.realEstateTypes.HOUSE",
        APARTMENT: "snapshotEditor.dataTab.realEstateTypes.APARTMENT",
        PROPERTY: "snapshotEditor.dataTab.realEstateTypes.PROPERTY",
        OFFICE_PRACTICES:
          "snapshotEditor.dataTab.realEstateTypes.OFFICE_PRACTICES",
        STORE_RETAIL: "snapshotEditor.dataTab.realEstateTypes.STORE_RETAIL",
        HOSPITALITY: "snapshotEditor.dataTab.realEstateTypes.HOSPITALITY",
        HALLS_WAREHOUSE_PRODUCTION:
          "snapshotEditor.dataTab.realEstateTypes.HALLS_WAREHOUSE_PRODUCTION",
        AGRICULTURE_FORESTRY:
          "snapshotEditor.dataTab.realEstateTypes.AGRICULTURE_FORESTRY",
        LEISURE_COMMERCIAL:
          "snapshotEditor.dataTab.realEstateTypes.LEISURE_COMMERCIAL",
        OTHER: "snapshotEditor.dataTab.realEstateTypes.OTHER",
        CUSTOM: "snapshotEditor.dataTab.realEstateTypes.CUSTOM",
      },
      locationExpose: {
        locDescExceedError:
          "snapshotEditor.dataTab.locationExpose.locDescExceedError",
      },
    },
    pointsOfInterest: {
      label: "snapshotEditor.pointsOfInterest.label",
      traffic: "snapshotEditor.pointsOfInterest.traffic",
      supplies: "snapshotEditor.pointsOfInterest.supplies",
      education: "snapshotEditor.pointsOfInterest.education",
      leisure: "snapshotEditor.pointsOfInterest.leisure",
      sport: "snapshotEditor.pointsOfInterest.sport",
      health: "snapshotEditor.pointsOfInterest.health",
      infrastructure: "snapshotEditor.pointsOfInterest.infrastructure",
      tourism: "snapshotEditor.pointsOfInterest.tourism",
      description: "snapshotEditor.pointsOfInterest.description",
      station: "snapshotEditor.pointsOfInterest.station",
      tram_stop: "snapshotEditor.pointsOfInterest.station",
      bus_stop: "snapshotEditor.pointsOfInterest.bus_stop",
      motorway_link: "snapshotEditor.pointsOfInterest.motorway_link",
      charging_station: "snapshotEditor.pointsOfInterest.charging_station",
      fuel: "snapshotEditor.pointsOfInterest.fuel",
      supermarket: "snapshotEditor.pointsOfInterest.supermarket",
      chemist: "snapshotEditor.pointsOfInterest.chemist",
      kiosk_post_office: "snapshotEditor.pointsOfInterest.kiosk_post_office",
      kiosk: "snapshotEditor.pointsOfInterest.kiosk",
      post_office: "snapshotEditor.pointsOfInterest.post_office",
      kindergarten: "snapshotEditor.pointsOfInterest.kindergarten",
      school: "snapshotEditor.pointsOfInterest.school",
      university: "snapshotEditor.pointsOfInterest.university",
      playground: "snapshotEditor.pointsOfInterest.playground",
      park: "snapshotEditor.pointsOfInterest.park",
      restaurant: "snapshotEditor.pointsOfInterest.restaurant",
      bar_pub: "snapshotEditor.pointsOfInterest.bar_pub",
      bar: "snapshotEditor.pointsOfInterest.bar",
      pub: "snapshotEditor.pointsOfInterest.pub",
      theatre: "snapshotEditor.pointsOfInterest.theatre",
      fitness_centre: "snapshotEditor.pointsOfInterest.fitness_centre",
      swimming_pool: "snapshotEditor.pointsOfInterest.swimming_pool",
      sports_centre: "snapshotEditor.pointsOfInterest.sports_centre",
      sports_hall: "snapshotEditor.pointsOfInterest.sports_hall",
      pharmacy: "snapshotEditor.pointsOfInterest.pharmacy",
      doctors: "snapshotEditor.pointsOfInterest.doctors",
      dentist: "snapshotEditor.pointsOfInterest.dentist",
      clinic: "snapshotEditor.pointsOfInterest.clinic",
      hospital: "snapshotEditor.pointsOfInterest.hospital",
      surface: "snapshotEditor.pointsOfInterest.surface",
      parking_garage: "snapshotEditor.pointsOfInterest.parking_garage",
      "multi-storey": "snapshotEditor.pointsOfInterest.multi-storey",
      underground: "snapshotEditor.pointsOfInterest.underground",
      wind_turbine: "snapshotEditor.pointsOfInterest.wind_turbine",
      power_pole: "snapshotEditor.pointsOfInterest.power_pole",
      tower: "snapshotEditor.pointsOfInterest.tower",
      pole: "snapshotEditor.pointsOfInterest.pole",
      hotel: "snapshotEditor.pointsOfInterest.hotel",
      museum: "snapshotEditor.pointsOfInterest.museum",
      attraction: "snapshotEditor.pointsOfInterest.attraction",
      distance: "snapshotEditor.pointsOfInterest.distance",
      footpath: "snapshotEditor.pointsOfInterest.footpath",
      bicycle: "snapshotEditor.pointsOfInterest.bicycle",
      auto: "snapshotEditor.pointsOfInterest.auto",
    },
    positionIndices: {
      label: "snapshotEditor.positionIndices.label",
      description: "snapshotEditor.positionIndices.description",
      notAvailable: "snapshotEditor.positionIndices.notAvailable",
      evening_entertainment:
        "snapshotEditor.positionIndices.evening_entertainment",
      health: "snapshotEditor.positionIndices.health",
      near_supply: "snapshotEditor.positionIndices.near_supply",
      public_transport: "snapshotEditor.positionIndices.public_transport",
      kids: "snapshotEditor.positionIndices.kids",
      culture: "snapshotEditor.positionIndices.culture",
      sports: "snapshotEditor.positionIndices.sports",
      individual_mobility: "snapshotEditor.positionIndices.individual_mobility",
      tourism: "snapshotEditor.positionIndices.tourism",
    },
    socialDemographics: {
      label: "snapshotEditor.socialDemographics.label",
      description: "snapshotEditor.socialDemographics.description",
      censusData: "snapshotEditor.socialDemographics.censusData",
      federalElections: "snapshotEditor.socialDemographics.federalElections",
      politicalParty: "snapshotEditor.socialDemographics.politicalParty",
    },
    environmentInfo: {
      label: "snapshotEditor.environmentInfo.label",
      description: "snapshotEditor.environmentInfo.description",
      particulateMatterPollution:
        "snapshotEditor.environmentInfo.particulateMatterPollution",
      avgLoad: "snapshotEditor.environmentInfo.avgLoad",
      daysAboveLimit: "snapshotEditor.environmentInfo.daysAboveLimit",
    },
    economicMetric: {
      label: "snapshotEditor.economicMetric.label",
      description: "snapshotEditor.economicMetric.description",
      contactUs: "snapshotEditor.economicMetric.contactUs",
    },
    notAllowedToUse: "snapshotEditor.notAllowedToUse",
    screenshotName: "snapshotEditor.screenshotName",
    addNewLocationModalTitle: "snapshotEditor.addNewLocationModalTitle",
    addNewLocationModalBtn: "snapshotEditor.addNewLocationModalBtn",
    addNewLocationModalSuccess: "snapshotEditor.addNewLocationModalSuccess",
    addNewLocationModalFailed: "snapshotEditor.addNewLocationModalFailed",
    english: "snapshotEditor.english",
    german: "snapshotEditor.german",
    outputLanguage: "snapshotEditor.outputLanguage",
    invertBaseColor: "snapshotEditor.invertBaseColor",
    errorSendingAiRequest: "snapshotEditor.errorSendingAiRequest",
  },

  realEstate: {
    sync: "realEstate.sync",
    syncCRM: "realEstate.syncCRM",
    importCSV: "realEstate.importCSV",
    createObject: "realEstate.createObject",
    linkedCardSnippet: "realEstate.linkedCardSnippet",
    csvImportError: "realEstate.csvImportError",
    csvImportErrorStructure: "realEstate.csvImportErrorStructure",
    csvImportSuccess: "realEstate.csvImportSuccess",
    csvImported: "realEstate.csvImported",
    paymentMethods: "realEstate.paymentMethods",
    csvImportPrepareText: "realEstate.csvImportPrepareText",
    csvImportPrepareDescription: "realEstate.csvImportPrepareDescription",
    csvImportExampleCsv: "realEstate.csvImportExampleCsv",
    csvImportExampleXls: "realEstate.csvImportExampleXls",
    crmImported: "realEstate.crmImported",
    crmImportSuccess: "realEstate.crmImportSuccess",
    syncErrorOnImporting: "realEstate.syncErrorOnImporting",
    syncCompleted: "realEstate.syncCompleted",
    syncPropertyFailed: "realEstate.syncPropertyFailed",
    synchronized: "realEstate.synchronized",
    setFilter: "realEstate.setFilter",
    equipment: "realEstate.equipment",
    note: "realEstate.note",
    createdOn: "realEstate.createdOn",
    lastCall: "realEstate.lastCall",
    numberOfVisits: "realEstate.numberOfVisits",
    addressDisplayed: "realEstate.addressDisplayed",
    newObject: "realEstate.newObject",
    unknownObject: "realEstate.unknownObject",
    objectSavedSuccessfully: "realEstate.objectSavedSuccessfully",
    objectErrorWhileSaving: "realEstate.objectErrorWhileSaving",
    enterExtraStatus: "realEstate.enterExtraStatus",
    enterObjectName: "realEstate.enterObjectName",
    enterValidUrl: "realEstate.enterValidUrl",
    enterRealEstateAddress: "realEstate.enterRealEstateAddress",
    showInSnippet: "realEstate.showInSnippet",
    objectName: "realEstate.objectName",
    enterObjectNamePlaceholder: "realEstate.enterObjectNamePlaceholder",
    externalLink: "realEstate.externalLink",
    externalLinkPlaceholder: "realEstate.externalLinkPlaceholder",
    minPrice: "realEstate.minPrice",
    pricePlaceholder: "realEstate.pricePlaceholder",
    maxPrice: "realEstate.maxPrice",
    costType: "realEstate.costType",
    costTypePlaceholder: "realEstate.costTypePlaceholder",
    statuses: {
      IN_PREPARATION: "realEstate.statuses.IN_PREPARATION",
      FOR_RENT: "realEstate.statuses.FOR_RENT",
      FOR_SALE: "realEstate.statuses.FOR_SALE",
      RENTED: "realEstate.statuses.RENTED",
      SOLD: "realEstate.statuses.SOLD",
      NEW_CONSTRUCTION: "realEstate.statuses.NEW_CONSTRUCTION",
      ARCHIVED: "realEstate.statuses.ARCHIVED",
      RESERVED: "realEstate.statuses.RESERVED",
      MARKET_OBSERVATION: "realEstate.statuses.MARKET_OBSERVATION",
    },
    costTypes: {
      RENT_MONTHLY_COLD: "realEstate.costTypes.RENT_MONTHLY_COLD",
      RENT_MONTHLY_WARM: "realEstate.costTypes.RENT_MONTHLY_WARM",
      SELL: "realEstate.costTypes.SELL",
    },
    realEstateSize: "realEstate.realEstateSize",
    realEstateSizePlaceholder: "realEstate.realEstateSizePlaceholder",
    propertySize: "realEstate.propertySize",
    propertySizePlaceholder: "realEstate.propertySizePlaceholder",
    energyEfficiency: "realEstate.energyEfficiency",
    energyEfficiencyPlaceholder: "realEstate.energyEfficiencyPlaceholder",
    furnishing: {
      GARDEN: "realEstate.furnishing.GARDEN",
      BALCONY: "realEstate.furnishing.BALCONY",
      BASEMENT: "realEstate.furnishing.BASEMENT",
      GUEST_REST_ROOMS: "realEstate.furnishing.GUEST_REST_ROOMS",
      UNDERFLOOR_HEATING: "realEstate.furnishing.UNDERFLOOR_HEATING",
      GARAGE_PARKING_SPACE: "realEstate.furnishing.GARAGE_PARKING_SPACE",
      FITTED_KITCHEN: "realEstate.furnishing.FITTED_KITCHEN",
      ACCESSIBLE: "realEstate.furnishing.ACCESSIBLE",
    },
  },
  potentialCustomers: {
    createTargetGroup: "potentialCustomers.createTargetGroup",
    sendQuestionnaire: "potentialCustomers.sendQuestionnaire",
    livingConcept: "potentialCustomers.livingConcept",
    importantAddresses: "potentialCustomers.importantAddresses",
    customerDeletedSuccess: "potentialCustomers.customerDeletedSuccess",
    customerDeleteConfirm: "potentialCustomers.customerDeleteConfirm",
    customerDeleteTitle: "potentialCustomers.customerDeleteTitle",
    customerCreateTitle: "potentialCustomers.customerCreateTitle",
    customerCreatedSuccess: "potentialCustomers.customerCreatedSuccess",
    customerCreatedFailed: "potentialCustomers.customerCreatedFailed",
    pleaseEnterName: "potentialCustomers.pleaseEnterName",
    pleaseEnterEmail: "potentialCustomers.pleaseEnterEmail",
    approvalIsRequired: "potentialCustomers.approvalIsRequired",
    nameTitle: "potentialCustomers.nameTitle",
    emailTitle: "potentialCustomers.emailTitle",
    sentCopyEmail: "potentialCustomers.sentCopyEmail",
    whatHappenWhenYouClick: "potentialCustomers.whatHappenWhenYouClick",
    questionnaireText1: "potentialCustomers.questionnaireText1",
    questionnaireText2: "potentialCustomers.questionnaireText2",
    questionnaireText3: "potentialCustomers.questionnaireText3",
    questionnaireText4: "potentialCustomers.questionnaireText4",
    questionnaireText5: "potentialCustomers.questionnaireText5",
    customerAgreesToReceive: "potentialCustomers.customerAgreesToReceive",
    newTargetGroup: "potentialCustomers.newTargetGroup",
    unknownName: "potentialCustomers.unknownName",
    potentialCustomerSubmitSuccessful:
      "potentialCustomers.potentialCustomerSubmitSuccessful",
    potentialCustomerSubmitFailed:
      "potentialCustomers.potentialCustomerSubmitFailed",
    nameRequired: "potentialCustomers.nameRequired",
    nameOfTargetGroup: "potentialCustomers.nameOfTargetGroup",
    preferred: "potentialCustomers.preferred",
    myFavorite: "potentialCustomers.myFavorite",
    typesOfTransportations: "potentialCustomers.typesOfTransportations",
    localities: "potentialCustomers.localities",
  },
  mapSnapshots: {
    copiedToClipboard: "mapSnapshots.copiedToClipboard",
    locationNotFound: "mapSnapshots.locationNotFound",
    duplicateConfirmation: "mapSnapshots.duplicateConfirmation",
    duplicateFailed: "mapSnapshots.duplicateFailed",
    deleteConfirmation: "mapSnapshots.deleteConfirmation",
    deleteFailed: "mapSnapshots.deleteFailed",
    templateSaved: "mapSnapshots.templateSaved",
    openEditor: "mapSnapshots.openEditor",
    queryLimitReached: "mapSnapshots.queryLimitReached",
    startAnalysis: "mapSnapshots.startAnalysis",
    buyNewContingent: "mapSnapshots.buyNewContingent",
    noVisits: "mapSnapshots.noVisits",
    copyLink: "mapSnapshots.copyLink",
    cancelTemplate: "mapSnapshots.cancelTemplate",
    setAsTemplate: "mapSnapshots.setAsTemplate",
    duplicateCard: "mapSnapshots.duplicateCard",
    duplicateCardSuccessfully: "mapSnapshots.duplicateCardSuccessfully",
    settingSaved: "mapSnapshots.settingSaved",
    settingSavingError: "mapSnapshots.settingSavingError",
    changingNoteError: "mapSnapshots.changingNoteError",
    yourCardSnippet: "mapSnapshots.yourCardSnippet",
    noteField: "mapSnapshots.noteField",
    directLink: "mapSnapshots.directLink",
    htmlSnippet: "mapSnapshots.htmlSnippet",
    companyTemplate: "mapSnapshots.companyTemplate",
  },
  companyProfile: {
    title: "companyProfile.title",
  },
  yourProfile: {
    title: "yourProfile.title",
    profileUpdated: "yourProfile.profileUpdated",
    profileUpdateError: "yourProfile.profileUpdateError",
    pleaseEnterName: "yourProfile.pleaseEnterName",
    pleaseEnterEmail: "yourProfile.pleaseEnterEmail",
    yourEmail: "yourProfile.yourEmail",
    yourName: "yourProfile.yourName",
    logoSaved: "yourProfile.logoSaved",
    primaryColorSaved: "yourProfile.primaryColorSaved",
    exportSettingsReset: "yourProfile.exportSettingsReset",
    exportSettings: "yourProfile.exportSettings",
    exportSettingsDescription: "yourProfile.exportSettingsDescription",
    exportSettingsResetBtn: "yourProfile.exportSettingsResetBtn",
    yourPrimaryColor: "yourProfile.yourPrimaryColor",
    CRMSettings: "yourProfile.CRMSettings",
    CRMSettingsDescription: "yourProfile.CRMSettingsDescription",
    apiKey: "yourProfile.apiKey",
    connectionTestedSuccessfully: "yourProfile.connectionTestedSuccessfully",
    testAndSave: "yourProfile.testAndSave",
    token: "yourProfile.token",
    secret: "yourProfile.secret",
    endTestAndSelectPlan: "yourProfile.endTestAndSelectPlan",
    endTestAndSelectPlanBtn: "yourProfile.endTestAndSelectPlanBtn",
    yourCurrentPlan: "yourProfile.yourCurrentPlan",
    currentPlan: "yourProfile.currentPlan",
    managePaymentAndSubscription: "yourProfile.managePaymentAndSubscription",
    requestExecuted: "yourProfile.requestExecuted",
    settingAppliedTooltip: "yourProfile.settingAppliedTooltip",
    locationDescription: "yourProfile.locationDescription",
    locationRealEstateDescription: "yourProfile.locationRealEstateDescription",
    realEstateDescription: "yourProfile.realEstateDescription",
    equipmentDescription: "yourProfile.equipmentDescription",
    inlineFrame: "yourProfile.inlineFrame",
    linkWithAddress: "yourProfile.linkWithAddress",
    linkWoAddress: "yourProfile.linkWoAddress",
    syncWithCrm: "yourProfile.syncWithCrm",
  },
  tour: {
    tourEndingError: "tour.tourEndingError",
    mayWeHelp: "tour.mayWeHelp",
    doNotShowAnyMore: "tour.doNotShowAnyMore",
    noInterest: "tour.noInterest",
    startIntroduction: "tour.startIntroduction",
    next: "tour.next",
    exit: "tour.exit",
    tourDescription: {
      search: "tour.tourDescription.search",
      result: "tour.tourDescription.result",
      customers: "tour.tourDescription.customers",
      realEstates: "tour.tourDescription.realEstates",
      profile: "tour.tourDescription.profile",
      editor: "tour.tourDescription.editor",
      intMap: "tour.tourDescription.intMap",
      intSearch: "tour.tourDescription.intSearch",
    },
    searchPage: {
      welcome: "tour.searchPage.welcome",
      welcomeDescription: "tour.searchPage.welcomeDescription",
      lastSearchedAddresses: "tour.searchPage.lastSearchedAddresses",
      myRealEstates: "tour.searchPage.myRealEstates",
      transportationType: "tour.searchPage.transportationType",
      myCustomers: "tour.searchPage.myCustomers",
      importantAddress: "tour.searchPage.importantAddress",
      localityParams: "tour.searchPage.localityParams",
      startAnalysis: "tour.searchPage.startAnalysis",
    },
    realEstatesPage: {
      welcome: "tour.realEstatesPage.welcome",
      tableOverview: "tour.realEstatesPage.tableOverview",
      searchBtn: "tour.realEstatesPage.searchBtn",
      editBtn: "tour.realEstatesPage.editBtn",
      deleteBtn: "tour.realEstatesPage.deleteBtn",
      createBtn: "tour.realEstatesPage.createBtn",
    },
    customersPage: {
      welcome: "tour.customersPage.welcome",
      tableOverview: "tour.customersPage.tableOverview",
      searchBtn: "tour.customersPage.searchBtn",
      editBtn: "tour.customersPage.editBtn",
      deleteBtn: "tour.customersPage.deleteBtn",
      createBtn: "tour.customersPage.createBtn",
    },
    profilePage: {
      welcome: "tour.profilePage.welcome",
      form: "tour.profilePage.form",
      subscription: "tour.profilePage.subscription",
      requestContingent: "tour.profilePage.requestContingent",
    },
    editorPage: {
      welcome: "tour.editorPage.welcome",
      mapNavbar: "tour.editorPage.mapNavbar",
      toggleBounds: "tour.editorPage.toggleBounds",
      zoomToBounds: "tour.editorPage.zoomToBounds",
      goFullscreen: "tour.editorPage.goFullscreen",
      takeMapPicture: "tour.editorPage.takeMapPicture",
      sideBar: "tour.editorPage.sideBar",
      tabsIcons: "tour.editorPage.tabsIcons",
      showMapMenuBar: "tour.editorPage.showMapMenuBar",
      resetPosition: "tour.editorPage.resetPosition",
      mapMenuContents: "tour.editorPage.mapMenuContents",
      saveBtn: "tour.editorPage.saveBtn",
    },
    intMapPage: {
      welcome: "tour.intMapPage.welcome",
      map: "tour.intMapPage.map",
      sideMenu: "tour.intMapPage.sideMenu",
      publishIframe: "tour.intMapPage.publishIframe",
      resetPosition: "tour.intMapPage.resetPosition",
      mapNavbar: "tour.intMapPage.mapNavbar",
      zoomToBounds: "tour.intMapPage.zoomToBounds",
      goFullscreen: "tour.intMapPage.goFullscreen",
      takePicture: "tour.intMapPage.takePicture",
    },
  },

  integration: {
    absentTokensError: "integration.absentTokensError",
    wrongExportTypeGiven: "integration.wrongExportTypeGiven",
  },

  screenshot: {
    cropSuccessfull: "screenshot.cropSuccessfull",
  },

  subscriptions: {
    aboutMySubscription: "subscriptions.aboutMySubscription",
    timeForMore: "subscriptions.timeForMore",
    toActivateSwitchOnSubscription:
      "subscriptions.toActivateSwitchOnSubscription",
    subscribe: "subscriptions.subscribe",
    creditCard: "subscriptions.creditCard",
    paymentCanceled: "subscriptions.paymentCanceled",
    selectionTitle: "subscriptions.selectionTitle",
    perAddressPlusVAT: "subscriptions.perAddressPlusVAT",
    includes: "subscriptions.includes",
    oneAddressAndAnalysis: "subscriptions.oneAddressAndAnalysis",
    targetGroupFilter: "subscriptions.targetGroupFilter",
    interactiveMapForIframeAndPortal:
      "subscriptions.interactiveMapForIframeAndPortal",
    hyperlinkToMap: "subscriptions.hyperlinkToMap",
    qrCodeToMap: "subscriptions.qrCodeToMap",
    sitePlan: "subscriptions.sitePlan",
    licenceCost: "subscriptions.licenceCost",
    environmentalAnalysisAndReport:
      "subscriptions.environmentalAnalysisAndReport",
    overviewOfTheSituation: "subscriptions.overviewOfTheSituation",
    socioEconomicData: "subscriptions.socioEconomicData",
    noiseData: "subscriptions.noiseData",
    keyEconomicFigures: "subscriptions.keyEconomicFigures",
    dataAtZipCodeLevel: "subscriptions.dataAtZipCodeLevel",
    locationIndicies: "subscriptions.locationIndicies",
    preparationForSpecificTargetGroup:
      "subscriptions.preparationForSpecificTargetGroup",
    customerQuestionnaireInTheSystem:
      "subscriptions.customerQuestionnaireInTheSystem",
    automaticLocationExpose: "subscriptions.automaticLocationExpose",
    automaticDescription: "subscriptions.automaticDescription",
    automaticConversation: "subscriptions.automaticConversation",
    yourColorsLogosIcons: "subscriptions.yourColorsLogosIcons",
    userAccount: "subscriptions.userAccount",
    faqs: "subscriptions.faqs",
    optional: "subscriptions.optional",
    setupOfIndividualInterface: "subscriptions.setupOfIndividualInterface",
    perCardStyleFromOurCatalogue: "subscriptions.perCardStyleFromOurCatalogue",
    personalizedCardStyle: "subscriptions.personalizedCardStyle",
    allPricesExclusiveOfVAT: "subscriptions.allPricesExclusiveOfVAT",
    totalInclusiveVat: "subscriptions.totalInclusiveVat",
    unusedExpiredIn12Months: "subscriptions.unusedExpiredIn12Months",
    cardStylesSetupOnce: "subscriptions.cardStylesSetupOnce",
    tenAnalyzeAndPrepareAddress: "subscriptions.tenAnalyzeAndPrepareAddress",
    increase: "subscriptions.increase",
    monthlyQuota: "subscriptions.monthlyQuota",
    type: "subscriptions.type",
    quantity: "subscriptions.quantity",
    data: "subscriptions.data",
    errorWhenIncreaseLimit: "subscriptions.errorWhenIncreaseLimit",
    toastSubscriptionSuccess: "subscriptions.toastSubscriptionSuccess",
    toastSubscriptionError: "subscriptions.toastSubscriptionError",
    buyProduct: "subscriptions.buyProduct",
    integrationIdNotSpecified: "subscriptions.integrationIdNotSpecified",
    dataSent: "subscriptions.dataSent",
    productNotActivated: "subscriptions.productNotActivated",
    addressActivated: "subscriptions.addressActivated",
    planTypes: {
      PAY_PER_USE: "subscriptions.planTypes.PAY_PER_USE",
      BUSINESS_PLUS: "subscriptions.planTypes.BUSINESS_PLUS",
    },
    payPerUse: {
      queryContingentTitle: "subscriptions.payPerUse.queryContingentTitle",
      queryContingentDescription:
        "subscriptions.payPerUse.queryContingentDescription",
      queryContingentBigDescription:
        "subscriptions.payPerUse.queryContingentBigDescription",
      addressProcessContingentTitle:
        "subscriptions.payPerUse.addressProcessContingentTitle",
      addressProcessContingentDescription:
        "subscriptions.payPerUse.addressProcessContingentDescription",
      oneAddress: "subscriptions.payPerUse.oneAddress",
      buySingleQuery: "subscriptions.payPerUse.buySingleQuery",
      cardOf10: "subscriptions.payPerUse.cardOf10",
      buyCardOf10: "subscriptions.payPerUse.buyCardOf10",
      cardOf5: "subscriptions.payPerUse.cardOf5",
      buyCardOf5: "subscriptions.payPerUse.buyCardOf5",
    },
    business: {
      monthlySubscription: "subscriptions.business.monthlySubscription",
      businessPlus: "subscriptions.business.businessPlus",
      addressesAndAnalysisPro: "subscriptions.business.addressesAndAnalysisPro",
      addressesAndAnalysisProYearly:
        "subscriptions.business.addressesAndAnalysisProYearly",
      allFunctionsFromTenCard: "subscriptions.business.allFunctionsFromTenCard",
      threeUser: "subscriptions.business.threeUser",
      setupOfIndividualInterface:
        "subscriptions.business.setupOfIndividualInterface",
      twoSelectableCardStyles: "subscriptions.business.twoSelectableCardStyles",
      onboardingAndFaqs: "subscriptions.business.onboardingAndFaqs",
      perCardStyleFromOurCatalogue:
        "subscriptions.business.perCardStyleFromOurCatalogue",
      perUserAssignment: "subscriptions.business.perUserAssignment",
      industryDataAndMore: "subscriptions.business.industryDataAndMore",
      totalPerMonth: "subscriptions.business.totalPerMonth",
      totalPerYear: "subscriptions.business.totalPerYear",
      minimumTermForMonth: "subscriptions.business.minimumTermForMonth",
      minimumTermForYear: "subscriptions.business.minimumTermForYear",
      cardStyles: "subscriptions.business.cardStyles",
      annualSubscription: "subscriptions.business.annualSubscription",
    },
  },

  errors: {
    userNotFound: "errors.userNotFound",
    wrongIntegration: "errors.wrongIntegration",
    createSnapshot: "errors.createSnapshot",
    checkExpMatchLinkCfg: "errors.checkExpMatchLinkCfg",
  },

  company: {
    profile: {
      templatePlaceholder: "company.profile.templatePlaceholder",
      templateIdLabel: "company.profile.templateIdLabel",
      exportMatching: "company.profile.exportMatching",
      exportMatchDescription: "company.profile.exportMatchDescription",
    },
  },
};
